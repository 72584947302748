<template>
  <div class="user-page" :class="{ 'debug-background': item.debug }">
    <div class="content-wrapper">
      <h2>{{ item.title[title_lang] }}</h2>
    </div>
    <b-card title="Details">
      <b-row>
        <b-col md="9" xl="6">
          <!-- <b-form-group label="Company">
            <b-form-select
              v-model="item.id_company"
              :options="companyList"
              @change="companyChange"
            ></b-form-select>
          </b-form-group> -->
          <template v-if="default_lang">
            <b-form-group label="Title" label-for="title">
              <b-form-input v-model="item.title[title_lang]" />
              <LangOptions v-model="title_lang" :options="languages" />
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <b-form-textarea
                v-model="item.description[description_lang]"
                rows="3"
              />
              <LangOptions v-model="description_lang" :options="languages" />
            </b-form-group>
            <b-form-group>
              <template #label>
                Role List
                <InfoPopover
                  class="ml-1"
                  text="Select what roles should be included in this campaign"
                />
              </template>
              <TagsInput v-model="item.role_list" :tagsList="roleList" />
              <span class="muted" v-if="!item.id_company"
                >Select a company to see the list of roles</span
              >
              <span class="muted" v-else-if="!roleList.length"
                >This company has no roles</span
              >
            </b-form-group>
            <b-form-group>
              <template #label>
                Supervisor Role List
                <InfoPopover
                  class="ml-1"
                  text="Select what roles should be supervisors in this campaign"
                />
              </template>
              <TagsInput
                v-model="item.view_supervisor"
                :tagsList="viewSupervisorList"
              />
              <span class="muted" v-if="!item.id_company"
                >Select a company to see the list of supervisors</span
              >
              <span class="muted" v-else-if="!roleList.length"
                >This company has no roles</span
              >
            </b-form-group>
            <b-form-group label="Start" label-for="start">
              <b-form-datepicker
                v-model="item.data_inizio"
                placeholder="-/-/-"
              />
            </b-form-group>
            <b-form-group label="End" label-for="end">
              <b-form-datepicker v-model="item.data_fine" placeholder="-/-/-" />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="item.data_perc"
                switch
              ></b-form-checkbox>
              Dati percentuali
            </b-form-group>
            <div class="w-100 flex justify-content-start align-items-center">
              <b-form-checkbox v-model="item.target_avanzato" class="mt-3 mr-4">
                Add advanced targeting
                <InfoPopover
                  class="ml-1"
                  text="Enable selection by areas, departments and clusters"
                />
              </b-form-checkbox>
            </div>
            <div v-show="item.target_avanzato" class="mt-3">
              <div class="mt-3">
                <b-form-group>
                  <template #label>
                    Area List
                    <!-- <InfoPopover
                      class="ml-1"
                      text="Lista delle aree etc. Lorem ipsum dolor sit amet"
                    /> -->
                  </template>
                  <TagsInput v-model="item.ta_areas" :tagsList="areaList" />
                  <span class="muted" v-if="!item.id_company"
                    >Select a company to see the list of roles</span
                  >
                  <span class="muted" v-else-if="!areaList.length"
                    >This company has no areas</span
                  >
                </b-form-group>

                <b-form-group>
                  <template #label>
                    Department List
                    <!-- <InfoPopover
                      class="ml-1"
                      text="Lista delle aree etc. Lorem ipsum dolor sit amet"
                    /> -->
                  </template>
                  <TagsInput
                    v-model="item.ta_departments"
                    :tagsList="departmentList"
                  />
                  <span class="muted" v-if="!item.id_company"
                    >Select a company to see the list of department</span
                  >
                  <span class="muted" v-else-if="!departmentList.length"
                    >This company has no department</span
                  >
                </b-form-group>

                <b-form-group>
                  <template #label>
                    Cluster List
                    <!-- <InfoPopover
                      class="ml-1"
                      text="Lista delle aree etc. Lorem ipsum dolor sit amet"
                    /> -->
                  </template>
                  <TagsInput
                    v-model="item.ta_clusters"
                    :tagsList="clusterList"
                  />
                  <span class="muted" v-if="!item.id_company"
                    >Select a company to see the list of roles</span
                  >
                  <span class="muted" v-else-if="!clusterList.length"
                    >This company has no cluster</span
                  >
                </b-form-group>
              </div>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <div class="content-wrapper">
      <b-button squared variant="primary" size="lg" @click="saveItem"
        ><template v-if="hasNextRoute()">Save and continue</template
        ><template v-else>Save</template></b-button
      >
    </div>
    <b-button
      v-if="item.debug"
      class="fixed-button py-2"
      v-b-toggle.sidebar-iframe
    >
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar
      @hidden="resetDataSidebarIframe"
      class="right-sidebar iframe-sidebar"
      id="sidebar-iframe"
      width="500px"
      right
      shadow
      lazy
    >
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select
          @change="getUrlDemo"
          :options="usersList"
          v-model="userSelected"
        >
        </b-select>
        <!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
      </div>
      <div class="iframe-phone">
        <iframe
          class="iframe-phone-iframe"
          :src="url_demo"
          frameborder="0"
        ></iframe>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "./../../axiosServices/AxiosService";
import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
import { mapGetters } from "vuex";
import { SET_CURRENT_COMPANY } from "@/store/actions/config";

export default {
  data() {
    return {
      item: {
        id: null,
        id_tipo_campagna: this.$route.path.includes("campaign")
          ? 2
          : this.$route.path.includes("dataVisualization")
          ? 3
          : 1,
        id_company: null,
        title: {},
        description: {},
        role_list: [],
        ta_areas: [],
        ta_departments: [],
        ta_clusters: [],
        data_inizio: "",
        data_fine: "",
        debug: null,
        target_avanzato: false,
        area: "",
        department: "",
        cluser: "",
        url_mission_cover: "",
        mission_description: {},
        avatar_personalizzati: false,
        assegnazione_badge: false,
        order: 0,
        view_supervisor: [],
      },
      title_lang: null,
      description_lang: null,
      companyList: [],
      roleList: [],
      areaList: [],
      departmentList: [],
      clusterList: [],
      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,

      tmp_languages: null,
      tmp_default_lang: null,

      viewSupervisorList: [],
    };
  },
  service: null,
  companyService: null,
  created() {
    this.service = new AxiosService("Campaign");
    this.companyService = new AxiosService("Company");
    if (!this.isNewCampaign) {
      this.title_lang = this.default_lang.value || this.default_lang;
      this.description_lang = this.default_lang.value || this.default_lang;
    }
    // this.getUrlDemo();

    this.item.id_company = this.$store.getters.currentCompany;
  },
  async mounted() {
    // this.service.getOptions("Company").then((data) => {
    //   this.companyList = data;
    //   if (this.isNewCampaign && this.companyList.length) {
    //     this.item.id_company = this.companyList[0].value;
    //     this.companyChange();
    //   }
    // });
    this.companyChange();

    if (!this.isNewCampaign) {
      this.service.readById(this.$route.params.campaignId).then((data) => {
        this.item = data;
        this.getUsersByCampaign();
        if (this.item.role_list === null) this.item.role_list = [];
        if (this.item.view_supervisor === null || !this.item.view_supervisor)
          this.item.view_supervisor = [];
        this.getRolesByCompany();
        if (this.item.ta_areas === null) this.item.ta_areas = [];
        this.getAreasByCompany();
        if (this.item.ta_departments === null) this.item.ta_departments = [];
        this.getDepartmentsByCompany();
        if (this.item.ta_clusters === null) this.item.ta_clusters = [];
        this.getClustersByCompany();
        this.$store.dispatch(SET_CURRENT_COMPANY, this.item.id_company);
      });
    }
  },
  methods: {
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.item.id +
              "/" +
              this.userSelected +
              "/details/campaign";
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    companyChange() {
      this.item.role_list.splice(0);
      this.item.view_supervisor.splice(0);
      this.item.ta_areas.splice(0);
      this.item.ta_departments.splice(0);
      this.item.ta_clusters.splice(0);
      this.getRolesByCompany();
      this.getAreasByCompany();
      this.getDepartmentsByCompany();
      this.getClustersByCompany();
      this.setCampaignLanguages(this.item.id_company);

      // this.setCompanyLanguages(this.item.id_company);
      this.$store.dispatch(SET_CURRENT_COMPANY, this.item.id_company);
    },
    setCampaignLanguages(id_company) {
      console.log(
        "setCampaignLanguages",
        id_company,
        this.item,
        this.$store.getters.currentCompany
      );

      if (id_company) {
        this.companyService.readById(id_company).then((company) => {
          this.tmp_languages = this.all_languages.filter((x) =>
            company.lingue.includes(x.ID)
          );
          if (!this.tmp_languages.length) {
            this.tmp_languages = this.all_languages;
          }
          this.tmp_default_lang = this.tmp_languages[0];
        });
      }
    },
    saveItem() {
      const validationObject = this.$validate("campaign", this.item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      this.item.data_inizio =
        this.item.data_inizio.split("T")[0] + "T00:00:00.000";
      this.item.data_fine = this.item.data_fine.split("T")[0] + "T23:59:59.000";

      if (this.item.id) {
        if (!this.isNewCampaign) {
          this.service
            .update(this.item)
            .then((response) => {
              if (response.id == this.item.id) {
                this.$emit("update-company", this.item.id_company);
                this.$successToast("Details saved");
                this.toNextRoute();
              } else {
                this.$errorToast();
                return false;
              }
            })
            .catch(() => {
              this.$errorToast();
              return false;
            });
        }
      } else {
        this.item.debug = true;
        this.service
          .create(this.item)
          .then((response) => {
            if (response.id) {
              this.item.id = response.id;
              this.$successToast("Campaign created");
              this.toNextRoute();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "Details");
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "Details", this.item.id);
    },
    getRolesByCompany() {
      if (this.item.id_company) {
        this.service
          .getOptions("UserRole/" + this.item.id_company)
          .then((data) => {
            this.roleList = data;
            this.viewSupervisorList = data;
          });
      }
    },
    getAreasByCompany() {
      if (this.item.id_company) {
        this.service
          .getOptions("AT_Areas/" + this.item.id_company)
          .then((data) => (this.areaList = data));
      }
    },
    getDepartmentsByCompany() {
      if (this.item.id_company) {
        this.service
          .getOptions("AT_Department/" + this.item.id_company)
          .then((data) => (this.departmentList = data));
      }
    },
    getClustersByCompany() {
      if (this.item.id_company) {
        this.service
          .getOptions("AT_Cluster/" + this.item.id_company)
          .then((data) => (this.clusterList = data));
      }
    },
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.item.id) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.item.id
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    languages() {
      return this.tmp_languages || this.$parent.companyLanguages;
    },
    default_lang() {
      return this.tmp_default_lang || this.$parent.default_lang;
    },
    all_languages() {
      return this.$store.getters.languages;
    },
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    item: {
      handler() {
        if (
          this.item.id &&
          this.item.id_company !== this.$store.getters.currentCompany
        ) {
          // Show alert or modal to warn that changing company is not possible
          this.$alert("Cannot change company for an existing item");
          // Maintain original item.id_company value
          this.item.id_company = this.$store.getters.currentCompany;
        } else {
          this.$store.dispatch(SET_CURRENT_COMPANY, this.item.id_company);
        }
      },
    },
    currentCompanyFromStore: {
      handler() {
        this.item.id_company = this.currentCompanyFromStore;
        this.getAreasByCompany();
        this.getDepartmentsByCompany();
        this.getClustersByCompany();
        this.getRolesByCompany();
        this.setCampaignLanguages(this.item.id_company);
      },
    },
    default_lang() {
      if (this.default_lang) {
        this.title_lang = this.default_lang.value;
        this.description_lang = this.default_lang.value;
      }
    },
  },
};
</script>
